import React from "react";
import { AllSections, Sections } from "@components/Shared/AllSections/AllSections";
import Layout from "@components/Shared/Templates/Layout";
import FamilyHeroSection from "@components/FamilyOfCompanies/FamilyHeroSection";
import { FamilyHeroContent } from "@components/FamilyOfCompanies/FamilyHeroContent";
import { useApplication } from "@ryerson/frontend.application";
import { Grid, Row, Column } from "@ryerson/frontend.layout";
import { FamilyCompaniesContent } from "@components/FamilyOfCompanies/Content";
import { ContentSection } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { css } from "@emotion/react";
import { Link } from "@ryerson/frontend.navigation";
import ContactUs from "@components/Shared/ContactUsRefactor/ContactUs";
import { Media } from "@ryerson/frontend.layout";
import CompanyComponent, { CompanyCard } from "@components/FamilyOfCompanies/Company/Company";
import styled from "@emotion/styled";
import { useHasMounted } from "@components/Hooks";
import Meta from "@components/Shared/model/MetaHelmet";
import { MetaObject } from "@content/ryerson/about-us/family-of-companies";

type NavigableItem = {
	id: string;
	logoImageUrl: string;
};

type SubNavigationType = {
	content: NavigableItem[];
};

const GridBox = styled.div`
	width: 100%;
	height: 206px;
	box-sizing: border-box;
	position: relative;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media (max-width: ${theme.breakpoints.lg}) {
				height: 80px;
			}
		`;
	}}
`;

const SubNavigation: React.FC<SubNavigationType> = ({ content }) => {
	const { theme } = useTheme();
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<Grid
					gutter={0}
					style={css`
						box-sizing: border-box;
						border: 1px solid ${theme.colors.primary.gray};
					`}
				>
					<Row gutter={0}>
						{content.map((navItem: NavigableItem, index: number) => {
							return (
								<Column
									key={index}
									gutter={0}
									xs={6}
									sm={6}
									md={6}
									lg={4}
									style={css`
										background-color: ${theme.colors.primary.darkerGray};
									`}
								>
									<Link to={"#" + navItem.id}>
										<GridBox
											theme={theme}
											css={css`
												border-right: 1px solid
													${(index + 1) % 3 === 0
														? "transparent"
														: theme.colors.primary.gray};
												border-bottom: 1px solid
													${index > 11
														? "transparent"
														: theme.colors.primary.gray};
											`}
										>
											<img
												css={css`
													max-width: 260px;
													max-height: 132px;
													position: absolute;
													top: 0;
													left: 0;
													bottom: 0;
													right: 0;
													margin: auto;
												`}
												src={navItem.logoImageUrl}
											/>
										</GridBox>
									</Link>
								</Column>
							);
						})}
						<Column
							gutter={0}
							xs={6}
							sm={6}
							md={6}
							lg={4}
							style={css`
								background-color: ${theme.colors.primary.darkerGray};
							`}
						>
							<GridBox
								theme={theme}
								css={css`
									border-right: 1px solid transparent;
									border-bottom: 1px solid transparent;
								`}
							>
								<img
									css={css`
										max-width: 260px;
										max-height: 132px;
										position: absolute;
										top: 0;
										left: 0;
										bottom: 0;
										right: 0;
										margin: auto;
									`}
									src={
										"/images/family-of-companies/family-of-companies-logo-small.png"
									}
								/>
							</GridBox>
						</Column>
					</Row>
				</Grid>
			</Media>
			<Media lessThan="lg">
				<Grid
					gutter={0}
					style={css`
						box-sizing: border-box;
						border: 1px solid ${theme.colors.primary.gray};
					`}
				>
					<Row gutter={0}>
						{content.map((navItem: NavigableItem, index: number) => {
							return (
								<Column
									key={index}
									gutter={0}
									xs={6}
									sm={6}
									md={6}
									lg={4}
									style={css`
										background-color: ${theme.colors.primary.darkerGray};
									`}
								>
									<Link to={"#" + navItem.id}>
										<GridBox
											theme={theme}
											css={css`
												border-right: 1px solid
													${(index + 1) % 2 === 0
														? "transparent"
														: theme.colors.primary.gray};
												border-bottom: 1px solid
													${index > 14
														? "transparent"
														: theme.colors.primary.gray};
											`}
										>
											<img
												css={css`
													max-width: 110px;
													max-height: 69px;
													position: absolute;
													top: 0;
													left: 0;
													bottom: 0;
													right: 0;
													margin: auto;
												`}
												src={navItem.logoImageUrl}
											/>
										</GridBox>
									</Link>
								</Column>
							);
						})}
						<Column
							gutter={0}
							xs={12}
							sm={12}
							md={12}
							lg={4}
							style={css`
								background-color: ${theme.colors.primary.darkerGray};
							`}
						>
							<GridBox
								theme={theme}
								css={css`
									border-right: 1px solid transparent;
									border-bottom: 1px solid transparent;
								`}
							>
								<img
									css={css`
										max-width: 110px;
										max-height: 69px;
										position: absolute;
										top: 0;
										left: 0;
										bottom: 0;
										right: 0;
										margin: auto;
									`}
									src={
										"/images/family-of-companies/family-of-companies-logo-small.png"
									}
								/>
							</GridBox>
						</Column>
					</Row>
				</Grid>
			</Media>
		</>
	);
};

export default () => {
	const { theme } = useTheme();
	const {
		localization: { language },
	} = useApplication();
	const companyContent = FamilyCompaniesContent[language];
	const sections: Sections[] = [];
	const subNav: NavigableItem[] = [];
	let i: number = 0;
	companyContent.companies.forEach((content: CompanyCard) => {
		content.index = i;
		sections.push({
			title: content.companyDetails.companyName,
			cmp: <CompanyComponent companyCard={content} />,
		});
		subNav.push({
			id: content.companyDetails.companyName.toLowerCase().replace(/\s+/g, "-"),
			logoImageUrl: content.companyDetails.logoImageUrl,
		});
		i++;
	});
	if (!useHasMounted()) return null;
	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<FamilyHeroSection content={FamilyHeroContent[language]} />
			<Media greaterThanOrEqual="lg">
				<ContentSection
					type="split"
					split={{
						splitDirection: "vertical",
						splitColors: [
							{
								color: theme.colors.tertiary.background,
								percentage: "60%",
							},
							{
								color: theme.colors.secondary.background,
								percentage: "40%",
							},
						],
					}}
				>
					<SubNavigation content={subNav} />
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection
					type="split"
					split={{
						splitDirection: "vertical",
						splitColors: [
							{
								color: theme.colors.tertiary.background,
								percentage: "60%",
							},
							{
								color: theme.colors.primary.background,
								percentage: "40%",
							},
						],
					}}
				>
					<SubNavigation content={subNav} />
				</ContentSection>
			</Media>
			<AllSections accordionMultiple={false} sections={sections} />
			<ContactUs background="primary" withImage={true} oneButtonVariant={true} />
		</Layout>
	);
};
